<template>
  <content-wrapper>
    <h2>Our Products</h2>
    <div class="product-grid">
      <product-card
        v-for="product in products"
        :key="product.slug"
        v-bind:product="product"
      />
    </div>
  </content-wrapper>
</template>

<script>
import ContentWrapper from '@/components/ContentWrapper';
import ProductCard from '@/components/ProductCard';
import productData from '@/data/Products';

export default {
  name: 'Services',
  components: {
    ContentWrapper,
    ProductCard,
  },
  data: () => ({
    products: productData.products.sort((a, b) => a.name > b.name),
  }),
};
</script>
