<template>
  <div class="card">
    <router-link :to="`/product/${product.slug}`">
      <div class="image">
        <img :src="product.images[0].jpg" :alt="product.images[0].alt" loading="lazy">
      </div>
    </router-link>
    <div class="description">
      <h3>{{ product.category }}</h3>
      <h4>{{ product.name }}</h4>
      <hr>
      <p>{{ product.description }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Services',
  props: ['product'],
};
</script>

<style lang="scss" scoped>
.card {
  height: 18em;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 5px;
  overflow: hidden;
  -webkit-box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;
  box-shadow: 15px 15px 27px #e1e1e3, -15px -15px 27px #ffffff;

  &:hover {
    .image {
      height: 18em;
    }

    .description {
      height: 0em;
      padding: 0px 1em;
    }

    .social-icons {
      background-color: white;

      &:hover {
        background-color: var(--accent-color);
        cursor: pointer;
      }
    }
  }

  // Top card section
  .image {
    height: 6em;
    width: 100%;
    position: Absolute;
    top: 0px;
    -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  // Bottom card section
  .description {
    background-color: #fafafc;
    height: 12em;
    width: 100%;
    position: absolute;
    bottom: 0em;
    -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    padding: 0.5em;
    text-align: left;

    h3 {
      font-size: large;
      color: var(--accent-color);
      padding-bottom: 0.25em;
    }

    h4 {
      font-size: larger;
      padding-bottom: 0.25em;
    }
  }

  .social-icons {
    width: 1em;
    height: 1em;
    background-color: black;
    position: absolute;
    bottom: 1em;
    left: 1em;
    -webkit-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
}
</style>
